<template>
    <v-dialog
      v-model="dialogModel"
      :retain-focus="false"
      content-class="mv-0 mh-0 pv-0 ph-0 d-flex flex-column justify-center align-center fill-height"
      persistent
      >
          <v-card :width="smAndUp ? '750px' : undefined">
            <v-card-title>
              <span class="text-h5">{{ editScenario ? 'Edit Scenario' : 'Import Scenario' }}</span>
            </v-card-title>
            <v-card-text>
                <v-form v-model="scenarioValid">
                  <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                      ref="nameFieldRef"
                      density="compact"
                      :class="isDarkTheme ? 'text-input-white' : ''" 
                      variant="outlined" 
                      label="Name" 
                      v-model="scenarioName"
                      :rules="scenarioNameRules"
                      :disabled="!!editScenario"
                      required></v-text-field>
                    </v-col>
                  </v-row>
                  <template v-if="!editScenario"> 
                    <v-row>
                      <v-col cols="12">
                        <v-file-input
                          v-model="scenarioFile"
                          density="compact"
                          prepend-icon="mdi-file-upload"
                          accept=".xlsx"
                          label="Scenario Data File (.xlsx)"
                          :rules="scenarioFileRules"
                          :disabled="false"
                        ></v-file-input>
                      </v-col>
                    </v-row>
                  </template>
                  <template v-if="editScenario">
                    <v-row>
                      <v-col cols="12">
                        <v-checkbox
                          density="compact"
                          v-model="isArchived"
                          label="Archived"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </template>
                  <v-row>
                    <v-col cols="5">
                      <v-checkbox
                        v-model="isAccessRestricted"
                        density="compact"
                        label="Limit Access by Org"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="7">
                      <v-select
                        :items="orgSelectionList"
                        item-title="displayName"
                        item-value="id"
                        prepend-icon="mdi-account-group"
                        density="compact"
                        label="Organizations With Access"
                        variant="outlined"
                        hide-details="auto"
                        multiple
                        chips
                        :disabled="!isAccessRestricted"
                        v-model="accessOrgIds"
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <template v-if="!editScenario">
                        <v-textarea
                        v-model="importStatusMsg"
                        variant="solo"
                        density="compact"
                        rows="4"
                        disabled
                        no-resize
                        ></v-textarea>
                      </template>
                      <template v-if="importValidationResults">
                        <v-card-text class="font-weight-bold pt-1">Validation Results</v-card-text>
                        <v-list
                          elevation="2"
                          :lines="false"
                          density="compact"
                          class="mx-2 my-2 rounded-lg"
                          style="height: 150px; overflow-y: scroll;">
                          <template v-if="importValidationResults.errors && importValidationResults.errors.length">
                            <v-list-item
                              v-for="error in importValidationResults.errors"
                              :key="error"
                              density="compact"
                              prepend-icon="mdi-alert"
                            >
                              <v-list-item-title>Error</v-list-item-title>
                              <v-list-item-subtitle>{{ error }}</v-list-item-subtitle>
                            </v-list-item>
                          </template>
                          <template v-if="importValidationResults.warnings && importValidationResults.warnings.length">
                            <v-list-item
                              v-for="warning in importValidationResults.warnings"
                              :key="warning"
                              density="compact"
                              prepend-icon="mdi-information-outline"
                            >
                              <v-list-item-title>Warning</v-list-item-title>
                              <v-list-item-subtitle>{{ warning }}</v-list-item-subtitle>
                            </v-list-item>
                          </template>
                          <v-list-item
                            v-if="(!importValidationResults.errors || !importValidationResults.errors.length) && (!importValidationResults.warnings || !importValidationResults.warnings.length)"
                            key="no-errors-or-warnings"
                            density="compact"
                            prepend-icon="mdi-check-circle"
                          >
                            <v-list-item-title>No Errors or Warnings Found</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </template>
                    </v-col>
                  </v-row>
                </v-container>
                </v-form>
              </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="cancelScenarioCommit">
                {{ editScenario ? 'Cancel' : 'Close'}}
              </v-btn>
              <v-btn text @click="commitScenario" :disabled="!commitScenarioEnabled">
                {{ editScenario ? 'Save' : 'Import'}}
              </v-btn>
            </v-card-actions>
          </v-card>
    </v-dialog>
</template>

<script>
import { computed, inject, ref, watch } from 'vue'
import { useDisplay, useTheme } from 'vuetify'
import { useAuth0 } from '@auth0/auth0-vue'
import { importTestDataFromFile, updateTest } from '../utils/data.js'
import { validAPINodeEntryMatchRegex, validAPINodeErrorMessage, validAPINodeFieldMaxLength } from '../utils/uiUtils.js'
import { Buffer } from 'buffer'
export default {
    name: 'ScenarioEditDialog',
    props: {
        modelValue: {
            type: Boolean,
            required: true
        },
        orgs: {
          type: Array,
          required: true,
        },
        editScenario: {
          type: Object,
          required: false,
          default: () => null
        }
    },
    emits: ['update:modelValue', 'scenario-saved'],
    setup(props, context) {
        // Logger
        const logger = inject('vuejs3-logger')

        const dialogModel = ref(false)

        // Form values

        const scenarioName = ref('')
        const scenarioFile = ref(null)
        const isArchived = ref(false)
        const isAccessRestricted = ref(false)
        const accessOrgIds = ref([])

        // Auto focus handling

        const nameFieldRef = ref(null)

        //Theme Handling
        const theme = useTheme()
        const isDarkTheme = ref(theme.current.value === 'dark')
        watch(theme.current, () => {
          if (theme.current.value === 'dark') {
            isDarkTheme.value = true
          } else {
            isDarkTheme.value = false
          }
        })

        // Responsive Sizing Logic
        const { smAndUp } = useDisplay()

        // V-Model 2-Way Binding
        watch(() => props.modelValue, (val) => {
          dialogModel.value = val
        }, { immediate: true })

        watch(dialogModel, (val) => {
          context.emit('update:modelValue', val) // Pass change up to parent component

          if (val && props.editScenario) {
            // Populate the fields with org to edit (if applicable)
            scenarioName.value = props.editScenario?.name ?? ''
            isArchived.value = props.editScenario?.archived ?? false
            isAccessRestricted.value = props.editScenario.orgAccess ? true : false
            accessOrgIds.value = props.editScenario?.orgAccess !== null ? props.editScenario.orgAccess : []
            // Autofocus the proper UI element after dialog becomes visible
            setTimeout(() => {
              nameFieldRef.value?.focus()
            }, 250)
          }
        })

        // Org selection list
        const orgSelectionList = ref([])

        watch(() => props.orgs, (newOrgList) => {
          if (newOrgList) {
            orgSelectionList.value = newOrgList.map(({ id, displayName }) => ({ id, displayName: (displayName || id) }))
          } else {
            orgSelectionList.value = []
          }
        }, { immediate: true })

        // Form Validation
        const scenarioValid = ref(false)
        const scenarioNameRules = ref([
          v => (v.length > 0 && v.length <= validAPINodeFieldMaxLength) || 'Must have a length from 1 to 128 characters.',
          v => v.search(validAPINodeEntryMatchRegex) !== -1 || validAPINodeErrorMessage,
        ])
        const scenarioFileRules = ref([
          v => (!!v && v.length > 0) || 'Scenario Data File is required',
        ])

        const commitScenarioEnabled = computed(() => {
          return props.editScenario ? scenarioValid.value : scenarioValid.value && scenarioFile.value?.length > 0
        })

        // Form Functions
        const resetForm = () => {
          scenarioName.value = ''
          scenarioFile.value = null
          isArchived.value = false
          isAccessRestricted.value = false
          accessOrgIds.value = []
          isImporting.value = false
          importStatusMsg.value = ''
          importValidationResults.value = null
        }

        // Save Functions
        const { getAccessTokenSilently, user } = useAuth0()
        const commitScenario = async () => {

            // Import or Save?
            if (props.editScenario) {
              // Modify / Save
              // Commit the save
              const saved = await updateTest(await getAccessTokenSilently(), user.value?.sub, {
                name: scenarioName.value,
                archived: isArchived.value,
                orgAccess: isAccessRestricted.value ? accessOrgIds.value : null
              })
              logger.debug(`Updated scenario with name '${scenarioName.value}': ${JSON.stringify(saved)}`)

              // Signal data updated
              context.emit('scenario-saved', saved)
            
              // Close the dialog
              dialogModel.value = false

              resetForm()
            } else {
              // Import
              const loadedScenario = await importScenario(scenarioFile.value)
              if (loadedScenario) {
                // Clear file field on success for safety to avoid accidental re-uploads
                scenarioFile.value = null
                // Signal data updated
                context.emit('scenario-saved', loadedScenario)
              }
            }
        }

        const cancelScenarioCommit = () => {
          // Close the dialog
          dialogModel.value = false

          resetForm()
        }

         // Test Data Import (Existing logic)
        const isImporting = ref(false)
        const importStatusMsg = ref('')
        const importValidationResults = ref(null)
        const importScenario = async (scenarioFile) => {
            logger.debug(`Attempting upload of scenario file '${JSON.stringify(scenarioFile?.[0])}'.`)
            isImporting.value = true
            importStatusMsg.value = ''
            importValidationResults.value = null
            
            const parseFilesPromises = scenarioFile.map(file => {
              return new Promise((resolve, reject) => {
                const fileName = `${file.name}`
                logger.debug(`Parsing test data from file '${fileName}' (size: ${file.size})...`)
                const reader = new FileReader()
                reader.readAsArrayBuffer(file)
                reader.onload = (event) => {
                  const b64 = Buffer.from(event.target.result).toString('base64')
                  logger.debug(`Finished parsing data from file '${fileName}'.`)
                  resolve({ data: b64, fileName })
                }
                reader.onerror = () => {
                  logger.error(`Error parsing test data from file '${fileName}'!`)
                  reject()
                }
              })
            })

            const fileDataList = await Promise.all(parseFilesPromises)
            const loadDataPromises = fileDataList.map(({ data: fileBinaryContent, fileName }) => {
              return new Promise((resolve, reject) => {
                importStatusMsg.value = 'Loading...'
                getAccessTokenSilently().then((accToken) => {
                  importTestDataFromFile(accToken, user.value?.sub, {
                    fileContent: fileBinaryContent,
                    test: scenarioName.value,
                    fileName,
                    description: '',
                    orgAccess: isAccessRestricted.value ? accessOrgIds.value : null
                  }).then(({result, message, errorData }) => {
                    if (result) {
                      logger.debug(`Got file upload result: ${JSON.stringify(result)}`)
                      importStatusMsg.value = `Success! Loaded '${scenarioName.value}' containing ${result.states} scenario turns and ${result.actions} actions. Check the validation results below for any non-fatal warnings.`
                      importValidationResults.value = result.validationResults ?? null
                      resolve(result)
                    } else {
                      importStatusMsg.value = message ?? 'Failed! There was a problem importing from the selected file.'
                      importValidationResults.value = errorData?.validationResults ?? null
                      resolve()
                    }
                  }).catch((err) => {
                    reject(err)
                  })
                }).catch((err) => {
                  reject(err)
                })
              })
            })
            const loadedScenarios = await Promise.all(loadDataPromises)
            
            // Reset Input/Messaging
            logger.debug('Finished uploading new scenario...')
            isImporting.value = false
            return loadedScenarios?.[0] ?? null
        }

        return {
            dialogModel,
            isDarkTheme,
            // Autofocus handling
            nameFieldRef,
            // Responsive Sizing
            smAndUp,
            // Form Fields
            scenarioName,
            scenarioFile,
            isArchived,
            isAccessRestricted,
            accessOrgIds,
            importValidationResults,
            importStatusMsg,
            // Form Selection Lists
            orgSelectionList,
            // Form Validation
            scenarioValid,
            scenarioNameRules,
            scenarioFileRules,
            commitScenarioEnabled,
            commitScenario,
            cancelScenarioCommit
        }
    }
  }
</script>

<style lang="scss" scoped>

.text-input-white {
  :deep(.v-field__input) {
    color: rgba(255, 255, 255, 0.6) !important;
  }
}

// HACK: fix v-combobox text entry field width bug
:deep(.v-field__input > input) {
  width: 100%;
}

// HACK: limit v-combobox item list height
:deep(.v-menu .v-overlay__content > .v-list) {
	max-height: 450px !important;
}

</style>